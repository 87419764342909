export const firebaseConfig = {
	apiKey: 'AIzaSyAAqHVwl-c4q7OtO2o2nsUcpu8DN-2z0F0',
	authDomain: 'fir-app-221116.firebaseapp.com',
	databaseURL: 'https://fir-app-221116.firebaseio.com',
	projectId: 'firebase-app-221116',
	storageBucket: 'firebase-app-221116.appspot.com',
	messagingSenderId: '895878490890',
};

export const environment = {
	envName: 'staging',
	production: false,
	debug: false,
	routingAppApiUrl: 'https://routingappapi-production.appspot.com',
	routingApiURL: 'https://api.flutaro.de',
	POSEIDON_URL: 'https://poseidon-prod.ey.r.appspot.com',
	ATHENE_URL: 'https://athene-prod.ey.r.appspot.com',
	firebaseConfig: firebaseConfig,
};
