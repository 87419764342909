import { Injectable } from '@angular/core';

import { FlutaroDataService } from '../FlutaroService';
import { VehicleStatusStoreChange } from '../../timetable/component/TimetableComponentClasses';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import {
	getVehicleByLicensePlate,
	migrateVehicleDriverIntoDriverScheduleEntry,
	parseVehicle,
} from '@flutaro/package/lib/functions/VehicleDataFunctions';
import { areStringsEqualIgnoringCaseAndSpaces } from '@flutaro/package/lib/functions/FlutaroStringNumberFunctions';
import { environment } from '../../../environments/environment';
import { FlutaroCollection } from '@flutaro/package/lib/model/FlutaroConstants';
import { deleteDriverFromVehicles, filterForTrailers } from '@flutaro/package/lib/functions/VehicleFunctions';
import { TimetableTrailerEntry } from '../../timetable/TimetableClasses';
import { createVehicleTrailerEntriesForDay } from '../../timetable/TimetableFunctions';
import { migrate_SetDefaultCreatedAtAttribute } from '@flutaro/package/lib/functions/AppDataMigrations';
import { areDateIntervalsOverlapping } from '@flutaro/package/lib/functions/DataDateFunctions';
import { addYears } from 'date-fns';

@Injectable({
	providedIn: 'root',
})
export class FlutaroVehicleService extends FlutaroDataService<Vehicle> {
	protected initProvider() {
		this.url = environment.routingApiURL + '/data/vehicles';
		this.collectionName = FlutaroCollection.VEHICLE;
		this.authFb.$vehicleIdsFilter.subscribe(($vehicleIdsFilter) => {
			if (!$vehicleIdsFilter) return;
			this.$data.next(this.filterDataServiceSpecific(this.$data.getValue()));
		});
		this.websocketService.vehicleSocket.subscribe((vehicleData) => this.handleWebSocketAction(vehicleData));
		this.websocketService.$websocketRecoveredFromFailingState.subscribe(async (isReconnected) => {
			if (!isReconnected) return;
			await this.getDataFromServer();
		});
	}

	/**
	 * Special Case due to current implementation / limitations of infrastructure - contractors filterEmail identifies user and uses contractors vehicles as filter
	 * @param data
	 * @protected
	 */
	protected filterDataServiceSpecific(data: Vehicle[]): Vehicle[] {
		const readOnlyVehicleIdsFilter = this.authFb.$vehicleIdsFilter.getValue();
		if (!readOnlyVehicleIdsFilter) return data;
		const vehicles = data.filter((vehicle) => readOnlyVehicleIdsFilter.includes(vehicle.backendId));
		const $driverIdsFilter = vehicles
			.filter((vehicle) => vehicle.driverSchedules?.length)
			.map((vehicle) => vehicle.driverSchedules.map((driverSchedule) => driverSchedule.entityId))
			.flat();
		this.authFb.$driverIdsFilter.next($driverIdsFilter);
		return vehicles;
	}

	async storeStatus(statusChange: VehicleStatusStoreChange): Promise<Vehicle> {
		if (statusChange.isNew) statusChange.vehicle.timetableSettings.statuses.push(statusChange.status);
		return await this.store(statusChange.vehicle);
	}

	/**
	 * This returns exactly one vehicle for the given licensePlate (if exisiting)
	 * LicensePlate should be a unique identifier and therefor only one vehicle should be found.
	 * @param licensePlate
	 * @returns {any}
	 */
	public getVehicleByLicensePlate(licensePlate: string): Vehicle | undefined {
		return getVehicleByLicensePlate(licensePlate, this.getData());
	}

	/**
	 * Gets all Vehicles assigned to a contractor by contractors backendId
	 * @param {string} contractorId
	 */
	getVehiclesForContractor(contractorId: string): Vehicle[] {
		if (!contractorId) return [];
		return this.getData().filter((vehicle) => vehicle.contractorId === contractorId);
	}

	getTrailers(): Vehicle[] {
		return filterForTrailers(this.getData());
	}

	public async removeDriversSchedulesFromVehicles(driverId: string): Promise<boolean> {
		const vehiclesWithDriverSchedule = deleteDriverFromVehicles(this.getData(), driverId);
		if (!vehiclesWithDriverSchedule?.length) return true;
		for (let vehicle of vehiclesWithDriverSchedule) {
			await this.store(vehicle);
		}
		return true;
	}

	getVehicleByEmail(email: string): Vehicle | undefined {
		if (!email?.length) return undefined;
		return this.getData().find((vehicle) => areStringsEqualIgnoringCaseAndSpaces(vehicle.vehicleEmail, email));
	}

	public getTrailerEntriesForVehicleByDate(vehicle: Vehicle, refDate: Date): TimetableTrailerEntry[] | undefined {
		if (!vehicle.trailerSchedules?.length) return undefined;
		return createVehicleTrailerEntriesForDay(vehicle, this.getTrailers(), refDate);
	}

	protected parseElement(newElement: Vehicle): Vehicle {
		if (newElement.chooseEmailBasedOnVehicle && !newElement.vehicleEmail) newElement.chooseEmailBasedOnVehicle = false; // TODO: can I be deleted?
		if (migrate_SetDefaultCreatedAtAttribute(newElement)) console.info(`Vehicle-Migration in previous log`);
		const companySettings = this.companyService.$companySettings.getValue();
		if (!companySettings.isDriverDeactivated) migrateVehicleDriverIntoDriverScheduleEntry(newElement);
		return parseVehicle(newElement);
	}

	protected prepareInternalData(data: Vehicle[]): Vehicle[] {
		this.analyzeVehiclesSchedulesOverlapping(data);
		return data;
	}

	/**
	 * Analyze if there are overlapping schedules for vehicles with same entity
	 * @param data
	 * @private
	 */
	private analyzeVehiclesSchedulesOverlapping(data: Vehicle[]) {
		for (const vehicle of data) {
			const hasOverlappingSchedules = vehicle.driverSchedules.some((schedule) => {
				return data.some((vehicle2) => {
					const overlappingEntitySchedule = vehicle2.driverSchedules.find((schedule2) => {
						return (
							schedule.id !== schedule2.id &&
							schedule.entityId === schedule2.entityId &&
							((!schedule.endDate && !schedule2.endDate) ||
								areDateIntervalsOverlapping(
									schedule.startDate,
									schedule.endDate ? schedule.endDate : addYears(schedule.startDate, 1),
									schedule2.startDate,
									schedule2.endDate ? schedule2.endDate : addYears(schedule2.startDate, 1),
									true,
								))
						);
					});
					if (overlappingEntitySchedule) {
						console.error(
							`Vehicle ${vehicle.licensePlate}/${vehicle.backendId} has overlapping schedules with vehicle ${vehicle2.licensePlate}/${vehicle2.backendId}`,
						);
					}
					return overlappingEntitySchedule;
				});
			});
		}
	}
}
